import myaxios from "./myaxios";

async function deleteCompetence(id) {
    let answer = await deleteCompetenceFromAPI(id);
    return answer;
}

async function deleteCompetenceFromAPI(id){
    return myaxios.delete('/api/competences/'+id,'DELETECOMPETENCE')
}

export {
    deleteCompetence
}