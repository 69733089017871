import myaxios from "./myaxios";

async function getCours() {
    let answer = await getCoursFromAPI();
    return answer;
}

async function getCoursFromAPI(){
    return myaxios.get('/api/cours/getAllCours', 'GETALLCOURS')

}

async function updateCoursById(id, data) {
    let answer = await updateCoursByIdFromAPI(id, data);
    return answer;
}

async function updateCoursByIdFromAPI(id, data){
    return myaxios.put('/api/cours/'+id,data,'UPDATECOURS')
}
async function getCoursById(id) {
    let answer = await await getCoursByIdFromAPI(id);
    return answer;
}

async function getCoursByIdFromAPI(id){
    return myaxios.get('/api/cours/'+id, 'GETCOURS')
}

async function createCoursFromAPI(data){
    return myaxios.post('/api/cours/',data, 'CREATECOURS')
}

async function createCours(data) {
    let answer = await createCoursFromAPI(data);
    return answer;
}

async function deleteCoursFromAPI(id){
    return myaxios.delete('/api/cours/'+id, 'DELETECOURS')
}

async function deleteCours(id) {
    let answer = await deleteCoursFromAPI(id);
    return answer;
}

export {
    createCours, deleteCours, getCoursById, updateCoursById, getCours
}