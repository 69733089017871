import myaxios from "./myaxios";

async function getClassesFromAPI() {
    let answer = await myaxios.get('api/classe/', 'GETCLASSES')
    return answer
}

async function getClasses() {
    let answer = await getClassesFromAPI()
    return answer
}

export {
    getClasses,
}