<template>
  <div>
    <v-text-field label="Label Question" v-model="details.libelle_question" />
    <v-text-field label="Content Question" v-model="details.contenu_question" />
    <v-textarea label="Expected Answer" v-model="expectedAnswer" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      details: {
        id_type_question: 5,
        libelle_type_question: 'Texte libre',
        libelle_question: '',
        contenu_question: '',
        options: [{ value: 1, answer: '' }]  // Initialize with one option having the expected answer
      },
    };
  },
  computed: {
    expectedAnswer: {
      get() {
        // Return the answer from the first option, or an empty string if not available
        return this.details.options.length > 0 ? this.details.options[0].answer : '';
      },
      set(newValue) {
        // Update the first option's answer to the new value
        if (this.details.options.length > 0) {
          this.details.options[0].answer = newValue;
        } else {
          // If no option exists, create one
          this.details.options.push({ value: 1, answer: newValue });
        }
      }
    }
  },
  watch: {
    details: {
      handler() {
        this.$emit('input', this.details);
      },
      deep: true
    }
  }
};
</script>
