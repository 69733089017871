import myaxios from "./myaxios";

async function getUsersFomApi() {
    let answer = await myaxios.get('api/users/', 'GETUSERS')
    return answer
}

async function getUsers() {
    let answer = await getUsersFomApi()
    return answer
}


export {
    getUsers
}