import myaxios from "./myaxios";

async function getNotesClassesFromAPI(idClasse) {
    let answer = await myaxios.get('api/note/notesClasse/' + idClasse, 'GETNOTESCLASSES')
    return answer
}

async function getNotesClasse(idClasse) {
    let answer = await getNotesClassesFromAPI(idClasse)
    return answer
}

async function getNotesEtudiantFromAPI(idEtudiant) {
    let answer = await myaxios.get('api/note/notesEtudiant/' + idEtudiant, 'GETNOTESETUDIANT')
    return answer
}

async function getNotesEtudiant(idEtudiant) {
    let answer = await getNotesEtudiantFromAPI(idEtudiant)
    return answer
}

export {
    getNotesClasse, getNotesEtudiant
}