<template>
  <div>
    <v-card>
      <v-card-title class="headline">
        <span class="text-h4">{{ question.libelle_question }}</span>
      </v-card-title>
      <v-card-text>
        <div class="text-h5"><strong>Enoncé:</strong> {{ question.contenu_question }}</div>
        <div><strong>Type de Question:</strong> {{ question.type_question }}</div>
        <div><strong>Cours:</strong> {{ question.cours.libelle_cours }}</div>
        <div>Compétence: {{ question.cours.competence.libelle_competence }}</div>
        <div><strong>Cout:</strong> {{ question.cout }}</div>
        <div><strong>Réponses:</strong>
          <div v-if="question.type_question === 'QCM multiple'">
            <v-checkbox
                v-for="(reponse, index) in question.reponses"
                :key="index"
                :label="reponse.valeur"
                :input-value="isResponseSelected(reponse.id_reponse)"
                @change="handleResponseChange($event, question.id_question, reponse.id_reponse, reponse.valeur, true)"
            ></v-checkbox>
          </div>
          <div v-else-if ="question.type_question === 'QCM simple'">
            <v-radio-group v-model="selectedResponse">
              <v-radio
                  v-for="(reponse, index) in question.reponses"
                  :key="index"
                  :label="`${reponse.valeur} (${reponse.points_attribution} points)`"
                  :value="reponse"
                  @change="handleResponseChange(true, question.id_question, reponse.id_reponse, reponse.valeur, true)"
              ></v-radio>
            </v-radio-group>
          </div>
          <div v-else-if="question.type_question === 'Texte libre'">
            <v-textarea
                label="Votre réponse"
                auto-grow
                v-model="freeTextResponse"
                @input="handleResponseChange(true, question.id_question, null, freeTextResponse)"
            ></v-textarea>
          </div>
          <div v-else-if="question.type_question === 'Hn'">
            <v-list>
              <v-list-item v-for="(item, index) in items" :key="index">
                <v-list-item-content>
                  {{ item.text }}
                </v-list-item-content>
                <v-btn small icon @click="moveUp(index)" :disabled="index === 0">
                  <v-icon>mdi-arrow-up</v-icon>
                </v-btn>
                <v-btn small icon @click="moveDown(index)" :disabled="index === items.length - 1">
                  <v-icon>mdi-arrow-down</v-icon>
                </v-btn>
              </v-list-item>
            </v-list>
          </div>
          <ul v-else>
            <li v-for="(reponse, index) in question.reponses" :key="index">
              {{ reponse.valeur }} ({{ reponse.points_attribution }} points)
            </li>
          </ul>
        </div>
        <v-btn color="primary" v-if="send === true" @click="validateAnswer">Valider</v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>



<script>
export default {
  props: {
    question: {
      type: Object,
      required: true
    },
    send : {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedResponse: null,
      selectedResponses: [],
      freeTextResponse: "",
      dialog: false,
      drag: false,
      items: [],
    };
  },
  methods: {
    validateAnswer() {

      this.$emit('validate', this.question.id_question, this.selectedResponses);
      this.selectedResponses = [];
    },

    moveUp(index) {
      if (index > 0) {
        const item = this.items[index];
        const aboveItem = this.items[index - 1];
        this.items.splice(index - 1, 2, { ...item, position: item.position - 1 }, { ...aboveItem, position: aboveItem.position + 1 });
        this.handleResponseChange(true, this.question.id_question, item.id_reponse, item.text);
      }
    },

    moveDown(index) {
      if (index < this.items.length - 1) {
        const item = this.items[index];
        const belowItem = this.items[index + 1];
        this.items.splice(index, 2, { ...belowItem, position: belowItem.position - 1 }, { ...item, position: item.position + 1 });
        this.handleResponseChange(true, this.question.id_question, item.id_reponse, item.text);
      }
    },
    updateItems() {
      if (this.question && this.question.type_question === 'Hn') {
        this.items = this.question.reponses.map((rep, index) => ({
          id_reponse: rep.id_reponse,
          text: rep.valeur,
          position: index + 1
        }));
      }
    },
    isResponseSelected(id) {

      return this.selectedResponses.includes(id);
    },
    handleResponseChange(isSelected, questionId, responseId, value, isMultiple = false) {
      if (this.question.type_question === 'Hn') {
        this.selectedResponses = this.items.map(item => ({questionId, responseId: item.id_reponse, value: item.text + item.position}));
      } else {
        if (isSelected) {
          if (!isMultiple) {
            responseId = this.question.reponses[0].id_reponse;
            this.selectedResponses = [];
          }
          this.selectedResponses.push({questionId, responseId, value});
        } else {
          this.selectedResponses = this.selectedResponses.filter(res => res.responseId !== responseId);
        }

      }
    }
  },
  watch: {
    question(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateItems();
        if (newValue) {
          this.dialog = true;
        }
      }
    }
  },
  mounted() {
    this.updateItems();
    if (this.question) {
      this.dialog = true;
      if (this.question.type_question === 'Hn') {
          this.selectedResponses = this.items.map(item => ({questionId: this.question.id_question, responseId: item.id_reponse, value: item.text + item.position}));
      }

    }
  }
}
</script>

<style scoped>
.text-h4 {
  font-size: 2em;
}
[draggable="true"] {
  cursor: grab;
}

[draggable="true"]:active {
  cursor: grabbing;
}

.text-h5 {
  font-size: 1.5em;
}
</style>