import { render, staticRenderFns } from "./Editeur_cours.vue?vue&type=template&id=21d791cf&scoped=true"
import script from "./Editeur_cours.vue?vue&type=script&lang=js"
export * from "./Editeur_cours.vue?vue&type=script&lang=js"
import style0 from "./Editeur_cours.vue?vue&type=style&index=0&id=21d791cf&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21d791cf",
  null
  
)

export default component.exports