<template>
  <div>
    <v-text-field label="Label Question" v-model="details.libelle_question" />
    <v-text-field label="Content Question" v-model="details.contenu_question" />
    <v-list>
      <v-list-item v-for="(option, index) in details.options" :key="index">
        <v-text-field v-model="option.valeur" label="Option Text" />
        <!-- Update checkbox to reflect correctValue -->
        <v-checkbox
            :input-value="option.points_attribution === 1"
            label="Correct Answer"
            @change="() => setCorrect(index)"
        />
        <v-btn icon @click="removeOption(index)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-list-item>
    </v-list>
    <v-btn @click="addOption">Add Option</v-btn>
  </div>
</template>

<script>
export default {
  data() {
    return {
      details: {
        id_type_question: 1,
        libelle_type_question: 'QCM simple',
        libelle_question: '',
        contenu_question: '',
        options: []
      }
    };
  },
  methods: {
    addOption() {
      this.details.options.push({ valeur: '', points_attribution: 0 });
    },
    removeOption(index) {
      this.details.options.splice(index, 1);
    },
    setCorrect(index) {
      this.details.options.forEach((option, idx) => {
        option.points_attribution = (idx === index ? 1 : 0);
      });
    }
  },
  watch: {
    details: {
      handler() {
        this.$emit('input', this.details);  // Emit changes to the parent if needed
      },
      deep: true
    }
  }
};
</script>
