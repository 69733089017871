<template>
  <div>
    <v-text-field label="nom de la question" v-model="details.libelle_question" />
    <v-text-field label="contenu de la question" v-model="details.contenu_question" />
    <v-list>
      <v-list-item v-for="(option, index) in details.options" :key="index">
        <v-text-field v-model="option.valeur" label="Option Text" />
        <v-text-field v-model="option.points_attribution" label="valeur" type="number" min="0" />
        <v-btn icon color="red" @click="removeOption(index)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-list-item>
    </v-list>
    <v-btn color="primary" @click="addOption">Add Option</v-btn>
  </div>
</template>


<script>
export default {
  data() {
    return {
      details: {
        id_type_question: 2,
        libelle_type_question: "QCM multiple",
        libelle_question: '',
        contenu_question: '',
        options: []
      }
    };
  },
  methods: {
    addOption() {
      this.details.options.push({ valeur: '', points_attribution: 0 });
    },
    removeOption(index) {
      this.details.options.splice(index, 1);
    },
    validateOptions() {
      this.normalizeValues();
      this.emitDetails();
    },
    normalizeValues() {
      const total = this.details.options.reduce((sum, option) => sum + parseFloat(option.points_attribution || 0), 0);
      if (total === 0) return; // Prevent division by zero

      this.details.options.forEach(option => {
        option.normalizedValue = (parseFloat(option.points_attribution) / total).toFixed(2); // Store normalized value
      });
      this.emitDetails();
    },
    emitDetails() {
      // Emit the normalized details up to the parent component
      this.$emit('input', this.details);
    }
  },
  watch: {
    'details.options': {
      handler() {
        this.normalizeValues(); // Normalize whenever any option changes
      },
      deep: true
    },
    details: {
      handler() {
        this.emitDetails(); // Emit changes when details object changes
      },
      deep: true
    }
  }
};
</script>
