import Vue from 'vue'
import Vuex from 'vuex'
// import gsap from 'gsap'
import createPersistedState from "vuex-persistedstate";
import {getNotesClasse, getNotesEtudiant} from '@/service/notes.service'
import { getClasses} from "@/service/classes.service";
import {getUsers} from "@/service/users.service";
import {createCours, deleteCours, getCours, getCoursById, updateCoursById} from "@/service/cours.service";
import {deleteCompetence} from "@/service/competence.service";
import {addQuestion, getTypesQuestions} from "@/service/questions.service";
import {
	createEtablissement,
	deleteEtablissement,
	getEtablissementById,
	getEtablissements
} from "@/service/etablissement";

Vue.use(Vuex)

const getInitState = () => ({
	state: {
		user: {},
		idCours: 0,
		qcm: {
			chrono: {
				min: 0,
				sec: 0
			}
		},
		dateDebut: null,
		cp_res: [],
		cas_pratique: {
			start: 0,
			balance: 600,
			points: 0,
			score: 0,
			keyword: "",
			marque: "",
			date: new Date(new Date().getTime() + 7 * 60000),
		},
		classes : [],
		notesClasse: [],
		notesSelectedEtudiant: [],
		users: [],
		typesQuestions: [],
		cours: [],
		newQuestion: [],
		etablissements: [],
	}
})

const store = new Vuex.Store({
	...getInitState(),
	mutations: {
		setTypesQuestions(state, typesQuestions) {
			state.typesQuestions = typesQuestions
		},
		addCours(state, id) {
			console.log("addCours",id)
		},
		setUsers(state, users) {
			state.users = users
		},
		setNotesEtudiant(state, notes) {
			state.notesSelectedEtudiant = notes
		},
		setClasses(state, classes) {
			state.classes = classes
		},
		setNotesClasse(state, notes) {
			state.notesClasse = notes
		},
		updateCours(state, id) {
			if (state.idCours !== id)  state.cas_pratique.start = 0;
			state.idCours = id;
		},
		setUser(state, user) {
			state.user = user
		},
		
		setBalance(state, balance) {
			state.cas_pratique.balance = balance
		},
		removeBalance(state, balance) {
			state.cas_pratique.balance -= balance
		},
		giveBalance(state, balance) {
			state.cas_pratique.balance += balance
		},
		deleteCours(state, id) {
			console.log("deleteCours",id)
		},
		deleteCompetence(state, id) {
			console.log("deleteCompetence",id)
		},
		fetchCours(state, id) {
			console.log("fetchCours",id)
		},
		update(state, id) {
			console.log("update",id)
		},
		setPoints(state, {score,note}) {
			state.cas_pratique.points = note
			state.cas_pratique.score = score
		},
		
		setDate(state, date) {
			state.cas_pratique.date = date
		},
		
		setChrono(state, chrono) {
			state.qcm.chrono.min = chrono[0];
			state.qcm.chrono.sec = chrono[1];
		},
		
		initVar2(state,res) {
			state.cp_res=res;
			
			state.start = 0;
			state.date = new Date(new Date().getTime() + 7 * 60000);
			state.balance = res.argent;
			state.cas_pratique.balance = res.argent;
			state.points = 0;
			state.cas_pratique.start = 1;
			state.cas_pratique.date = new Date(new Date().getTime() + 10*60000);//chrono de 10 min
			state.cas_pratique.points =0;
		},
		
		initVariables(state,argent) {
			state.start = 0;
			
			state.date = new Date(new Date().getTime() + 7 * 60000);
			state.balance = argent?argent:600;
			state.cas_pratique.balance = argent?argent:600;
			state.points = 0;
			state.dateDebut=null;
			
			
			state.cas_pratique.start = 1;
			state.cas_pratique.date = new Date(new Date().getTime() + 10*60000);//chrono de 10 min
			if (state.user.tiersTemps) state.cas_pratique.date = new Date(new Date().getTime() + 13*60000+33000);//chrono de 13 min et 20 sec
			state.cas_pratique.points =0;
			state.cas_pratique.score =0;
		},
		
		resetState(state) {
			Object.assign(state, getInitState())
		},

		setCourss(state, cours) {
			state.cours = cours
		},
		addQuestion(state, data) {
			state.newQuestion = data
		},

		setEtablissements(state, etablissements) {
			state.etablissements = etablissements
		},


		fetchEtablissement(state, id) {
			console.log("fetchEtablissement",id)
		},

		addEtablissement(state, data) {
			state.etablissements.push(data)
		},

		deleteEtablissement(state, id) {
			state.etablissements = state.etablissements.filter(etablissement => etablissement.id !== id)
		}
		

	},
	actions: {

		async addQuestionStore({commit},data) {
			commit('addQuestion', data)
			const res = await addQuestion(data)
			return res
		},

		async getCoursStore({ commit }) {
			try {
				const cours = await getCours();
				commit('setCourss', cours.data.data);
			} catch (error) {
				console.error('Error fetching avis:', error);
			}
		},

		async getTypesQuestionsStore({ commit }) {
			const res = await getTypesQuestions()
			commit('setTypesQuestions', res.data)
			return res
		},
		async getNotesClasse({ commit }, id_classe) {
			try {
				const notes = await getNotesClasse(id_classe);
				commit('setNotesClasse', notes.data);
			} catch (error) {
				console.error('Error fetching avis:', error);
			}
		},

		async getnotesEtudiant({ commit }, id_etudiant) {
			try {
				const notes = await getNotesEtudiant(id_etudiant);
				console.log(notes.data)
				commit('setNotesEtudiant', notes.data);
			} catch (error) {
				console.error('Error fetching avis:', error);
			}
		},

		async getClasses({ commit }) {
			try {
				const classes = await getClasses();
				commit('setClasses', classes.data.classes);
			} catch (error) {
				console.error('Error fetching avis:', error);
			}
		},

		async getUsers({ commit }) {
			try {
				const users = await getUsers();
				commit('setUsers', users.data.users);
			} catch (error) {
				console.error('Error fetching avis:', error);
			}
		},

		async createCoursStore({commit},data) {
			commit('addCours', data.idCours)
			console.log(data)
			const res = await createCours(data)
			return res
		},

		async deleteCoursStore({commit},id) {
			commit('deleteCours', 0)
			console.log(id)
			const res = await deleteCours(id)
			return res
		},

		async getCoursByIdStore({commit},id) {
			const res = await getCoursById(id)
			console.log(res)
			commit('fetchCours', id)
			console.log(id)
			return res
		},

		async updateCoursStore({commit},data) {
			console.log(data.id_cours)
			console.log(data)
			const res = await updateCoursById(data.id_cours, data)
			commit('update', data.id_cours)
			return res
		},

		async deleteCompetenceStore({commit},id) {
			commit('deleteCompetence', id)
			const res = await deleteCompetence(id)
			return res
		},

		async getEtablissementsStore({commit}) {
			const res = await getEtablissements()
			commit('setEtablissements', res.data)
			console.log("AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA")
			console.log(res.data)
			return res.data
		},

		async getEtablissementByIdStore({commit},id) {
			const res = await getEtablissementById(id)
			commit('fetchEtablissement', id)
			return res
		},

		async createEtablissementStore({commit},data) {
			const res = await createEtablissement(data)
			commit('addEtablissement', data)
			return res
		},

		async deleteEtablissementStore({commit},id) {
			commit('deleteEtablissement', id)
			const res = await deleteEtablissement(id)
			return res
		}

	},
	plugins: [createPersistedState({
		paths: ['cas_pratique', 'qcm','cp_res','idCours']
	})],
})
// store.commit('initVariables')

export default store
