<template>
  <v-container>
    <!-- Section de Filtrage avec Cartes -->
    <v-row>
      <v-col cols="12" md="5">
        <v-card outlined class="pa-3 mb-3">
          <v-select
              :items="this.cours"
          item-text="libelle_cours"
          item-value="id_cours"
          label="Sélectionnez un cours"
          v-model="selectedCourse"
          @change="filterQuestions"
          outlined
          dense
          ></v-select>
        </v-card>
      </v-col>
      <v-col cols="12" md="5">
        <v-card outlined class="pa-3 mb-3">
          <v-select
              :items="competences"
              item-text="libelle_competence"
              item-value="id_competence"
              label="Sélectionnez une compétence"
              v-model="selectedCompetence"
              @change="filterQuestions"
              outlined
              dense
          ></v-select>
        </v-card>
      </v-col>
      <v-col cols="12" md="2">
        <v-btn color="error" dark @click="resetFilters" class="mt-3">
          Réinitialiser
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="text-right" cols="12">
        <v-btn color="primary" dark @click="goToQuestionCreator">
          Créer une Question
        </v-btn>
      </v-col>
    </v-row>

    <!-- Tableau des Questions avec Recherche Intégrée -->
    <v-row>
      <v-card class="elevation-1 w-100">
        <v-card-title>
          Questions
          <v-spacer></v-spacer>
          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Rechercher"
              single-line
              hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
            :headers="headers"
            :items="filteredQuestions"
            :items-per-page="5"
            item-key="id_question"
            @click:row="selectQuestion"
            class="elevation-1"
        ></v-data-table>
      </v-card>
    </v-row>


    <questionView :question="selectedQuestion" :send="false" ></questionView>
  </v-container>
</template>

<script>
import myaxios from "@/service/myaxios";
import {mapActions, mapState} from "vuex";
import questionView from '@/views/questionView.vue';

export default {
  components: {
    questionView
  },
  data() {
    return {
      search: '',
      selectedCourse: null,
      selectedCompetence: null,
      selectedQuestion: null,
      questions: [],
      filteredQuestions: [],
      courses: [],
      competences: [],
      headers: [
        {text: 'Question', align: 'start', sortable: true, value: 'libelle_question'},
        {text: 'Cours', value: 'cours.libelle_cours'},
        {text: 'Competence', value: 'cours.competence.libelle_competence'},
        {text: 'Cout', value: 'cout', sortable: true}
      ],
    };
  },
  methods: {
    ...mapActions(['getCoursStore']),
    selectQuestion(item) {

      this.selectedQuestion = item;

    },

    fetchQuestions() {
      myaxios.get("/api/question")
          .then(response => {
            this.questions = response.data
            this.filteredQuestions = this.questions;
          })
          .catch(error => {
            console.error('Failed to load questions:', error);
          });
    },
    fetchCompetences() {
      myaxios.get("/api/competences/")
          .then(response => {
            this.competences = response.data.classes;  // Adjust according to your API response structure
          })
          .catch(error => {
            console.error('Failed to load competences:', error);
          });
    },
    resetFilters() {
      this.selectedCourse = null;
      this.selectedCompetence = null;
      this.filterQuestions();
    },
    filterQuestions() {
      const searchLower = this.search.toLowerCase();

      this.filteredQuestions = this.questions.filter(q => {
        const matchesSearch = q.libelle_question.toLowerCase().includes(searchLower) ||
            q.cours.libelle_cours.toLowerCase().includes(searchLower) ||
            q.cours.competence.libelle_competence.toLowerCase().includes(searchLower);
        const matchesCourse = !this.selectedCourse || q.cours.id_cours.toString() === this.selectedCourse.toString();
        const matchesCompetence = !this.selectedCompetence || q.cours.competence.id_competence.toString() === this.selectedCompetence.toString();

        return matchesSearch && matchesCourse && matchesCompetence;
      });
    },
    goToQuestionCreator() {
      this.$router.push({name: 'QuestionCreator'});
    },
  },
  computed: {
    ...mapState(['cours']),
  },

  watch: {
    search() {
      this.filterQuestions();
    },
    selectedCourse() {
      this.filterQuestions();
    },
    selectedCompetence() {
      this.filterQuestions();
    }
  },

  mounted() {
    this.getCoursStore();
    this.fetchQuestions();
    this.fetchCompetences();
  }
}
</script>
