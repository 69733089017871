import Vue from 'vue'
import Router from 'vue-router'
import cookies from "vue-cookies";
import store from "@/store";

import Accueil from '../views/Home.vue'
import Contact  from "@/views/Contact";
import Connect from "@/views/Connect";
import CGU from "@/views/CGU";
import Liste_Cours from "@/views/Liste_Cours";
import Home_Etudiant from "@/views/Home_Etudiant";
import Createur_cours from "@/views/Createur_cours.vue";
import Editeur_cours from "@/views/Editeur_cours.vue";
import QuestionsEditorComponent from "@/components/QuestionsEditorComponent.vue";
import QuestionList from "@/views/QuestionList.vue";
import ExamenList from "@/views/ExamenList.vue";
import ExamCreatorView from "@/views/ExamCreatorView.vue";

Vue.use(Router)

const ADMIN=2, FORMATEUR=3;

const router =  new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Accueil',
      component: Accueil,
      meta: {title: 'SEO-ELP | Accueil'},
    },
    {
      path: '/Contact',
      name: 'Contact',
      component: Contact,
      meta: {
        title: 'SEO-ELP | Contact',
        transition: 'transitioncontact'
      },
    },
    {
      path: '/createQuestion',
      name: 'QuestionCreator',
      component: QuestionsEditorComponent,
      meta: {
        title: 'SEO-ELP | question',
        transition: 'transitionquestion'
      },
    },
    {
      path: '/ExamCreator',
      name: 'ExamCreator',
      component: ExamCreatorView,
      meta: {
        title: 'SEO-ELP | question',
        transition: 'transitionquestion'
      },
    },
    {
      path: '/listQuestions',
      name: 'QuestionsList',
      component: QuestionList,
      meta: {
        title: 'SEO-ELP | question',
        transition: 'transitionquestion'
      },
    },
    {
      path: '/listExamens',
      name: 'ExamensList',
      component: ExamenList,
      meta: {
        title: 'SEO-ELP | question',
        transition: 'transitionquestion'
      },
    },
    {
      path: '/connexion',
      name: 'Connect',
      component: Connect,
      meta: {title: 'SEO-ELP | Connexion',},
    },
    {
      path: '/cgu',
      name: 'CGU',
      component: CGU,
      meta: {title: 'SEO-ELP | Mentions légales'},
    },
    {
      path: '/cours',
      name: 'Cours',
      component: Liste_Cours,
      meta: {
        auth: true,
        title: 'SEO-ELP | Mes cours',
        transition: 'transitioncours'
      },
    },
    {
      path: '/cours/create/:id_competence',
      name: 'Createur_cours',
      component: Createur_cours,
      props: true,
      meta: {
        auth: true,
        title: 'SEO-ELP | Création de cours',
      },
    },
    {
      path: '/cours/edit/:id_cours',
      name: 'Edit_cours',
      component: Editeur_cours,
      props: true,
        meta: {
            auth: true,
            title: 'SEO-ELP | Edition de cours',
        },
    },
    {
      path: '/cours/:id',
      name: 'Cours_show',
      component: () => import('../views/Cours_Detail.vue'),
      meta: {
        auth: true,
        title: 'SEO-ELP | Cours',
      },
    },
    {
      path: '/ressources/:souscat',
      name: 'Ressources',
      component: () => import('../views/Ressources.vue'),
      meta: {
        auth: true,
        title: 'SEO-ELP | Ressources',
      },
    },
      // /exercices = ancienne page recap des cas pratiques
    {
      path: '/qcm/:idCours',
      name: 'QCM_show',
      component: () => import('../views/QCM_show.vue'),
      meta: {
        auth: true,
        title: 'SEO-ELP | QCM'
      },
    },
    {
      path: '/classes',
      name: 'Classes',
      component: () => import('../views/Classes.vue'),
      meta: {
        auth: true,
        role: FORMATEUR,
        title: 'SEO-ELP | Mes classes',
        transition: 'transitionclasses'
      },
    },
    {
      path: '/notes',
      name: 'Notes',
      component: () => import('../views/Notes.vue'),
      meta: {
        auth: true,
        title: 'SEO-ELP | Notes',
      },
    },
    {
      path: '/caspratique/:id',
      name: 'Cas_pratique',
      component: () => import('../views/CP_Etudiant.vue'),
      meta: {
        auth: true,
        title: 'SEO-ELP | Cas pratique'
      },
    },
    {
      path: '/niveau3',
      name: 'Niveau3',
      component: () => import('../views/Niveau3.vue'),
      meta: {
        auth: true,
        title: 'SEO-ELP | Niveau 3'
      },
    },
    {
      path: '/notepratique/:id',
      name: 'Notes Cas pratique',
      component: () => import('../views/CP_Note.vue'),
      meta: {
        auth: true,
        title: 'SEO-ELP | Résulats cas pratique',
      },
    },
    {
      path: '/caspratique/board/:id',
        name: 'BoardCP',
      component: () => import('../views/CP_Board.vue'),
      meta: {
        auth: true,
        role: FORMATEUR,
        title: 'SEO-ELP | Tableau de bord cas pratique',
      },
    },
    {
        path: '/caspratique/edition/:id',
        name: 'EditionCP',
        component: () => import('../views/CP_Edition.vue'),
      meta: {
        auth: true,
        role: FORMATEUR,
        title: 'SEO-ELP | Tableau de bord cas pratique',
      },
    },
    {
      path: '/admin',
        name: 'Admin',
        component: () => import('../views/Administration.vue'),
      meta:{
        auth: true,
        role: ADMIN
      }
    },{
      path: '/messages',
        name: 'Messages',
        component: () => import('../views/Messages.vue'),
      meta:{
        auth: true,
        role: ADMIN
      }
    },
    {
      path: '/doc',
        name: 'Doc',
        component: () => import('../views/CP_DocCreation.vue'),
    },
    {
      path: '/jeuP4',
        name: 'Jeu',
        component: () => import('../views/Puissance4.vue'),
    },
    {
      path:'/cours/edit/:id',
        name:'EditCours',
        meta:{
          auth: true,
          role: FORMATEUR,
        },
        component: () => import('../views/Cours_Editeur.vue'),
    },
    {
      path:'/home',
        name:'Home',
        component: Home_Etudiant,
    },
    {
      path: "*",
      name: "NotFound",
      redirect: {name: "Accueil"},
    }
  ]
})

router.beforeEach((to, from, next) => {
  if(to.meta.auth) {
    //recuperation des cookies user directement
    //car la verif du router se passe avant le create() de App et donc avant le chargement du store
    //en cas de rechargement de page
    const currentUser = cookies.get("currentUser");
    if (!currentUser) {
      store.commit("setUser", {});
      alert("Votre session a expiré. Veuillez vous reconnecter.")
      next({path: '/connexion'})
    }
    else if (!to.meta.role || to.meta.role>=currentUser.idRole)
      next()
    else
      next({path: '/connexion'})
  } else
    next()
})

export default router
