import myaxios from "./myaxios";

async function getTypesQuestions() {
    let answer = await getTypesQuestionsFromAPI();
    return answer;
}

async function getTypesQuestionsFromAPI(){
    return myaxios.get('/api/question/typeQuestion/','GETTYPESQUESTIONS')
}

async function addQuestionFromApi(data){
    return myaxios.post('/api/question/',data,'ADDQUESTION')
}

async function addQuestion(data){
    let answer = await addQuestionFromApi(data);
    return answer;
}

export {
    getTypesQuestions,
    addQuestion
}