<template>
  <v-container>
    <!-- Carte pour le budget et le temps -->
    <v-card class="mb-5" outlined>
      <v-card-title>Configurer l'examen</v-card-title>
      <v-card-text>
        <v-text-field
            label="Nom de l'examen"
            v-model="libelle"
            type="text"
            outlined
            dense
        ></v-text-field>
        <v-text-field
            label="Budget de l'examen ($)"
            v-model="examBudget"
            type="number"
            prefix="$"
            outlined
            dense
        ></v-text-field>
        <v-text-field
            label="Temps de l'examen (minutes)"
            v-model="examTime"
            type="number"
            suffix="min"
            outlined
            dense
        ></v-text-field>
        <v-text-field
            label="Nombre de questions"
            v-model="numQuestions"
            type="number"
            @input="updateQuestionSelectors"
            outlined
            dense
        ></v-text-field>
      </v-card-text>
    </v-card>

    <!-- Carte pour les sélecteurs de questions -->
    <v-card class="mb-5" outlined>
      <v-card-title>Choisir les questions</v-card-title>
      <v-card-text>
        <div v-for="(item, index) in selectedQuestions" :key="index">
          <v-select
              :label="'Question ' + (index + 1)"
              v-model="selectedQuestions[index]"
              :items="allQuestions"
              item-text="libelle_question"
              item-value="id_question"
              outlined
              dense
          ></v-select>
        </div>
      </v-card-text>
    </v-card>
    <!-- Bouton pour enregistrer l'examen -->
    <v-btn color="success" @click="saveExam" class="mt-5">
      Enregistrer l'examen
    </v-btn>
  </v-container>
</template>

<script>
import myaxios from "@/service/myaxios";

export default {
  data() {
    return {
      libelle: '',
      examBudget: 0,
      examTime: 0,
      numQuestions: 0,
      selectedQuestions: [],
      allQuestions: [],
    };
  },
  methods: {

    fetchQuestions() {
      myaxios.get("/api/question")
          .then(response => {
            this.allQuestions = response.data
            for (let i = 0; i < this.allQuestions.length; i++) {
              this.allQuestions[i].libelle_question = this.allQuestions[i].libelle_question + ' (' + this.allQuestions[i].cout + ' $)';
            }
          })
          .catch(error => {
            console.error('Failed to load questions:', error);
          });
    },

    updateQuestionSelectors() {
      const num = parseInt(this.numQuestions);
      if (num < 0) {
        this.numQuestions = 0; // Évite les nombres négatifs
        this.selectedQuestions = [];
      } else {
        this.selectedQuestions = Array.from({ length: num }, () => null);
      }
    },
    saveExam() {
      const examData = {
        libelle_cas_pratique: this.libelle,
        argent: this.examBudget,
        temps: this.examTime,
        questions: this.selectedQuestions.filter(question => question !== null)
      };
      console.log(examData);
      myaxios({
        method: 'post',
        url: '/api/caspratique',
        data: examData
      })
          .then(response => {
            console.log('Exam saved:', response.data);
            this.$router.push('/exams');
          })
          .catch(error => {
            console.error('Failed to save exam:', error);
          });
    }
  },
  mounted() {
    this.fetchQuestions()
  }
};
</script>

<style scoped>
.v-card-title, .v-card-text {
  padding-top: 10px;
}
</style>
