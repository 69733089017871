<template>
  <div>
    <v-text-field label="Label Question" v-model="details.libelle_question" />
    <v-text-field label="Content Question" v-model="details.contenu_question" />
    <v-list dense>
      <v-list-item
          v-for="(level, index) in details.options"
          :key="index"
          draggable="true"
          @dragstart="onDragStart($event, index)"
          @dragend="onDragEnd"
          @dragover.prevent
          @drop="onDrop($event, index)"
          class="draggable-item"
      >
        <v-icon class="drag-handle">mdi-drag-horizontal</v-icon>
        <v-text-field v-model="level.valeur" label="Heading Label" />
        <v-text-field :value="level.points_attribution" label="Level Value" readonly />
        <v-btn icon color="red" @click="removeLevel(index)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-list-item>
    </v-list>
    <v-btn @click="addLevel">Add Level</v-btn>
  </div>
</template>

<script>
export default {
  data() {
    return {
      details: {
        id_type_question: 4,
        libelle_type_question: "Hn",
        libelle_question: '',
        contenu_question: '',
        options: []
      }
    };
  },
  methods: {
    addLevel() {
      const newLevelIndex = this.details.options.length + 1;
      this.details.options.push({ valeur: '', points_attribution: newLevelIndex });
    },
    removeLevel(index) {
      this.details.options.splice(index, 1);
      this.updateLevelValues();
    },
    onDragStart(event, index) {
      event.dataTransfer.setData("text/plain", index);
      event.dataTransfer.effectAllowed = "move";
      event.target.style.opacity = "0.6";
    },
    onDragEnd(event) {
      event.target.style.opacity = "";
    },
    onDrop(event, toIndex) {
      const fromIndex = event.dataTransfer.getData("text");
      this.reorderLevels(parseInt(fromIndex), toIndex);
      event.preventDefault();
    },
    reorderLevels(fromIndex, toIndex) {
      if (fromIndex === toIndex) return;
      const movedItem = this.details.options.splice(fromIndex, 1)[0];
      this.details.options.splice(toIndex, 0, movedItem);
      this.updateLevelValues();
    },
    updateLevelValues() {
      this.details.options.forEach((level, index) => {
        level.points_attribution = index + 1; // Update level value to match new order
      });
    }
  },
  watch: {
    details: {
      handler() {
        this.$emit('input', this.details);
      },
      deep: true
    }
  }
};
</script>

