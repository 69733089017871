<template>
	<div>
		<v-app-bar app style="border-bottom: #0d00ff 2px solid">
			<v-app-bar-nav-icon @click="drawer = true" v-if="modelTel"></v-app-bar-nav-icon>
			<router-link class="nav-logo" to="/" style="color: #3d54d9" v-if="modelTel">SEO-ELP</router-link>
			<div class="d-flex" style="justify-content: space-around; flex-grow: 1;" v-if="!modelTel">
				<router-link class="nav-logo lien" to="/">SEO-ELP</router-link>
				<router-link class="lien" to="/cours" v-if="user.role">Cours</router-link>
        <router-link class="lien" to="/listQuestions" v-if="user.role==='ADMIN'||user.role==='FORMATEUR'">Question</router-link>
        <router-link class="lien" to="/listExamens" v-if="user.role">Examen</router-link>
        <router-link class="lien" to="/notes" v-if="user.role==='ADMIN'||user.role==='FORMATEUR'">Notes</router-link>
				<v-menu offset-y open-on-hover v-if="user.role">
					<template v-slot:activator="{ on, attrs }">
						<div class="lien" v-bind="attrs" v-on="on">Ressources</div>
					</template>
					<v-list>
						<v-list-item><router-link class="lien" to="/ressources/outils">Outils</router-link></v-list-item>
						<v-list-item><router-link class="lien" to="/ressources/pro">Professionnels</router-link></v-list-item>
						<v-list-item><router-link class="lien" to="/ressources/plusloin">Pour aller plus loin</router-link></v-list-item>
						<v-list-item><router-link class="lien" to="/contact">Contacter l'administrateur</router-link></v-list-item>
						<v-list-item><div class="lien" @click="getmanuel">Manuel Utilisateur</div></v-list-item>
					</v-list>
				</v-menu>

				<router-link class="lien" to="/classes" v-if="user.role==='ADMIN'||user.role==='FORMATEUR'">Mes classes</router-link>
				<router-link class="lien" to="/admin" v-if="user?.role==='ADMIN'">Administration</router-link>
<!--				<router-link class="lien" to="/niveau3" v-if="user?.role==='ADMIN'">Niveau 3</router-link>-->

				<router-link class="connexion" v-if="user.role===undefined" to="/connexion">Connexion</router-link>
				<div v-else style="display: flex">
					<router-link class="lien" style="font-size: 1em; margin-right: 10px" to="/home">{{ user.username }}</router-link>
					<div class="connexion" @click="logout">Déconnexion</div>
				</div>
			</div>
			<router-link class="connexion" style="margin: auto" v-else-if="user.role===undefined" to="/connexion">Connexion</router-link>
		</v-app-bar>
		<!-- Add a navigation bar -->
		<v-navigation-drawer v-model="drawer" absolute style="position: fixed; z-index: 3">
			<v-list style="margin-top: 50px">
				<v-list-item v-if="user.role">
					<router-link class="lienDrawwer" to="/cours">Cours</router-link>
				</v-list-item>
				<v-list-item v-if="user.role">
					<router-link class="lienDrawwer" to="/notes">Notes</router-link>
				</v-list-item>
				<v-list-item style="min-height: auto; margin-top: 10px" v-if="user.role">Ressources : </v-list-item>
				<div style="margin-left: 30px;" v-if="user.role">
					<div><router-link class="lienDrawwer" to="/ressources/outils">Outils</router-link></div>
					<div><router-link class="lienDrawwer" to="/ressources/pro">Professionnels</router-link></div>
					<div><router-link class="lienDrawwer" to="/ressources/plusloin">Pour aller plus loin</router-link></div>
					<div><router-link class="lienDrawwer" to="/contact">Contacter l'administrateur</router-link></div>
					<div><div class="lienDrawwer" @click="getmanuel">Manuel Utilisateur</div></div>
				</div>

				<v-list-item v-if="user.role && user.role!=='ETUDIANT'">
					<router-link class="lienDrawwer" to="/classes">Mes classes</router-link>
				</v-list-item>
				<v-list-item v-if="user?.role==='ADMIN'">
					<router-link class="lienDrawwer" to="/admin" v-if="user?.role==='ADMIN'">Administration</router-link>
				</v-list-item>

				<hr><br>
				<v-list-item>
					<router-link class="connexion" v-if="user.role===undefined" to="/connexion">Connexion</router-link>
					<div v-else>
						<p><router-link class="lienDrawwer" style="font-size: 1em; margin-right: 10px" to="/home">{{ user.username }}</router-link></p>
						<a class="connexion" style="color: white" @click="logout">Déconnexion</a>
					</div>
				</v-list-item>
			</v-list>
		</v-navigation-drawer>
	</div>
</template>

<script>
import myaxios from "@/service/myaxios";
import router from "@/router";

export default {
	name: "NavBar",
	data() {
		return {
			drawer: false,
			modelTel: false,
			items: [['Cours', '/cours'], ['Cas pratiques', '/cas_pratique'], ['Notes', '/notes'], ['Mes classes', '/classes'], ['Administration', '/admin']]
		}
	},
	computed: {
		user() {
			return this.$store.state.user;
		},
	},
	methods: {
		changeRoute(index) {
			this.$router.push(this.items[index].toLowerCase());
		},
		logout() {
			myaxios.get("/api/auth/deconnexionVUE")
				.then(() => {
					this.$store.commit("setUser", {});
					this.$cookies.remove("currentUser");
					router.push("/").catch(() => {});
				})
		},
		getmanuel() {
			if (this.user.role === 'ETUDIANT')
				window.open("http://seo-elp.fr/mu_etudiant.pdf", "_blank");
			else if (this.user.role === 'FORMATEUR')
				window.open("http://seo-elp.fr/mu_formateur.pdf", "_blank");
			else if (this.user.role === 'ADMIN')
				window.open("http://seo-elp.fr/mu_administrateur.pdf", "_blank");
		}
	},
	mounted() {
		this.modelTel = window.innerWidth < 850;
		window.addEventListener('resize', () => {
			this.modelTel = window.innerWidth < 850;
		})
	}
}
</script>

<style scoped lang="scss">
@import "../assets/scss/_variables.scss";

/* NAV BAR */

.nav-logo {
	font-family: $big-font;
	font-weight: 700;
	font-size: 1.2em;
}

.connexion {
	font-family: $big-font;
	font-weight: 500;
	background: $primary-color;
	color: white;
	padding: 10px 10px;
	border-radius: 35px;
	text-align: right;
	cursor: pointer;
}

.lien {
	height: 100%;
	transition: .4s;
	color: #3d54d9;
	padding: 10px 0;
	font-size: 1.25em;
}

.lienDrawwer {
	color: black;
}

.lien:hover {
	background-color: #3d54d9;
	color: white;
	transition: .4s;
}

.lienDrawwer:hover {
	background-color: #3d54d9;
	color: white;
	transition: .4s;
}

</style>