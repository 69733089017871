<template>
  <v-container>
    <!-- Utilisation de cartes pour mieux structurer la page -->
    <v-card class="mb-5" outlined>
      <v-card-title>
        Sélectionnez un cours
      </v-card-title>
      <v-card-text>
        <v-select
            label="Choisir un cours"
            v-model="selectedCourse"
            :items="this.cours"
            item-text="libelle_cours"
            item-value="id_cours"
            @change="resetDetails"
            outlined
            dense
        ></v-select>
      </v-card-text>
    </v-card>

    <v-card class="mb-5" outlined>
      <v-card-title>
        Type de question
      </v-card-title>
      <v-card-text>
        <span>La fonction QCM avec image sera disponible prochainement, ne pas l'utiliser.</span>
        <v-select
            label="Sélectionner le type de question"
            v-model="selectedQuestionType"
            :items="questionTypes"
            item-text="name"
            item-value="type"
            return-object
            @change="resetDetails"
            outlined
            dense
        ></v-select>
      </v-card-text>
    </v-card>

    <v-card class="mb-5" outlined>
      <v-card-title>
        Coût de la question
      </v-card-title>
      <v-card-text>
        <v-text-field
            label="Entrez le coût de la question"
            v-model="questionCost"
            type="number"
            prefix="$"
            outlined
            dense
        ></v-text-field>
      </v-card-text>
    </v-card>

    <!-- Affichage conditionnel avec transition pour un rendu plus doux -->
    <v-fade-transition mode="out-in">
      <v-card key="selectedQuestionType" v-if="selectedQuestionType">
        <v-card-title>
          Détails de la question
        </v-card-title>
        <v-card-text>
          <component
              :is="getComponent(selectedQuestionType.type)"
              v-model="questionDetails"
          />
        </v-card-text>
      </v-card>
    </v-fade-transition>

    <!-- Bouton avec un style cohérent et moderne -->
    <v-btn color="success" @click="saveQuestion" class="mt-5">
      Enregistrer la question
    </v-btn>
  </v-container>
</template>


<script>
import SimpleChoiceComponent from './SimpleChoiceComponent';
import MultipleChoiceComponent from './MultipleChoiceComponent';
import ImageChoiceComponent from './ImageChoiceComponent';
import TextFieldComponent from './TextFieldComponent';
import HierarchicalComponent from './HierarchicalComponent';
import {mapActions, mapState} from "vuex";

export default {
  components: {
    SimpleChoiceComponent,
    MultipleChoiceComponent,
    ImageChoiceComponent,
    TextFieldComponent,
    HierarchicalComponent
  },

  data() {
    return {
      selectedCourse: null,
      courses: [],
      selectedQuestionType: null, // Ensure this is initialized to null or an actual default type
      questionDetails: {},
      questionCost: 0,
      questionTypes: [
        { type: 'simpleChoice', name: 'QCM Simple' },
        { type: 'multipleChoice', name: 'QCM Multiple' },
        { type: 'imageChoice', name: 'QCM with Image' },
        { type: 'textField', name: 'Texte libre' },
        { type: 'hierarchical', name: 'Hierarchical (Hn)' }
      ]
    };
  },
  computed: {
  ...mapState(['cours']),
  },
  created() {
    this.getCoursStore();
  },
  methods: {
  ...mapActions(['getCoursStore', 'addQuestionStore']),
    getComponent(type) {
      const mapping = {
        simpleChoice: 'SimpleChoiceComponent',
        multipleChoice: 'MultipleChoiceComponent',
        imageChoice: 'ImageChoiceComponent',
        textField: 'TextFieldComponent',
        hierarchical: 'HierarchicalComponent'
      };
      return type ? mapping[type] : null;
    },
    resetDetails() {
      this.questionDetails = {};
    },

    async saveQuestion() {
      // Check if the question type is 'QCM multiple'
      if (this.selectedQuestionType.type === 'multipleChoice') {
        // Normalize the values
        const total = this.questionDetails.options.reduce((sum, option) => sum + parseFloat(option.points_attribution || 0), 0);
        if (total !== 0) {
          this.questionDetails.options.forEach(option => {
            option.points_attribution = parseFloat(option.points_attribution) / total;
          });
        }
      }

      let data = {
        libelle_question: this.questionDetails.libelle_question,
        contenu_question: this.questionDetails.contenu_question,
        id_type_question: this.questionDetails.id_type_question,
        id_cours: this.selectedCourse,
        reponses: this.questionDetails.options,
        cout: this.questionCost
      }

      await this.addQuestionStore(data);
      await this.$router.push('/listQuestions');
    }

  }
};
</script>
