<template>
  <v-container>
    <v-list dense v-if="!currentCas">
      <!-- Bouton pour créer un examen visible seulement pour les rôles ADMIN et FORMATEUR -->
      <v-btn color="success" @click="createExam()" v-if="user.role === 'ADMIN' || user.role === 'FORMATEUR'">
        Créer un examen
      </v-btn>

      <!-- Liste des cas pratiques -->
      <v-list-item-group>
        <v-list-item v-for="cas in casPratiques" :key="cas.id_cas_pratique" >
          <!-- Condition pour afficher l'item si le cas est actif ou si l'utilisateur est ADMIN ou FORMATEUR -->
          <v-list-item-content v-if="cas.actif === 1 || user.role === 'ADMIN' || user.role === 'FORMATEUR'" class="d-flex justify-space-between align-center">

            <v-list-item-title>{{ cas.libelle_cas_pratique }} {{ cas.actif}}</v-list-item-title>


            <v-icon
                v-if="user.role === 'ADMIN' || user.role === 'FORMATEUR'"
                :color="cas.actif === 1 ? 'green' : 'grey'"
                @click="toggleExamStatus(cas)"
            >
              {{ cas.actif === 1 ? 'mdi-eye' : 'mdi-eye-off' }}
            </v-icon>

            <!-- Bouton pour démarrer l'examen -->
            <v-btn color="primary" @click="openExam(cas)">
              Démarrer l'examen
            </v-btn>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <div v-else>
      <div>temps : {{ Math.floor(remainingTime / 60) }}:{{ ('0' + remainingTime % 60).slice(-2) }}</div>
      <div>argent : {{currentCas.argent}}</div>
    <v-tabs  v-model="tab" background-color="deep-purple accent-4" dark grow>
      <v-tab v-for="(question, index) in currentCas.questions" :key="question.id_question">
        Question {{ index + 1 }}
      </v-tab>
      <v-tab-item v-for="(question) in currentCas.questions" :key="question.id_question">
        <question-view :question="question" :send="true" @validate="handleValidation"></question-view>
      </v-tab-item>
    </v-tabs>
    </div>

    <v-btn v-if="currentCas" @click="closeExam" color="red darken-1" class="mt-3">Retour aux cas pratiques</v-btn>

    <v-dialog v-model="showResultDialog" max-width="600px">
      <v-card>
        <v-card-title class="headline">Exam Result</v-card-title>
        <v-card-text>
          <p>Score: {{ examResult.score }}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="showResultDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </v-container>
</template>

<script>
import QuestionView from '../views/questionView.vue';
import myaxios from "@/service/myaxios";
import '@mdi/font/css/materialdesignicons.css';

export default {
  components: {
    QuestionView
  },
  data() {
    return {
      casPratiques: [],
      currentCas: null,
      tab: 0,
      timer: null,
      idTentative: null,
      remainingTime: 0,
      showResultDialog: false,
      examResult: {
        score: 0,
        tempsUtilise: 0
      }
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },

  methods: {

    async toggleExamStatus(cas) {
      const action = cas.actif === 1 ? 'setInactif' : 'setActif';
      await myaxios.post(`/api/caspratique/${action}/${cas.id_cas_pratique}`)
          .then(response => {
            console.log('Exam status updated:', response.data);
            // Trouver l'index du cas et mettre à jour réactivement
            const index = this.casPratiques.findIndex(c => c.id_cas_pratique === cas.id_cas_pratique);
            if (index !== -1) {
              this.$set(this.casPratiques, index, {
                ...cas,
                actif: cas.actif === 1 ? 0 : 1
              });
            }
          })
          .catch(error => console.error('Failed to update exam status:', error));
    },
    async submitQuestionResponse(questionId, responseId, value) {
      await myaxios.post('/api/caspratique/submit', {
        id_tentative: this.idTentative,
        id_question: questionId,
        id_reponse: responseId,
        valeur_reponse: value
      })
          .then(response => {
            console.log('Response submitted successfully:', response.data);

          })
          .catch(error => {
            console.error('Failed to submit response:', error);
          });
    },

    async handleValidation(questionId, responses) {
      const question = this.currentCas.questions.find(q => q.id_question === questionId);
      if (question.cout > this.currentCas.argent) {
        alert('Le coût de cette question est supérieur à votre budget restant. Vous ne pouvez pas valider cette question.');
        return;
      }

      this.currentCas.argent -= question.cout;

      // Use Promise.all to wait for all responses to be submitted
      await Promise.all(responses.map(response =>
          this.submitQuestionResponse(response.questionId, response.responseId, response.value)
      ));

      // Navigate to the next question or close the exam
      const currentIndex = this.currentCas.questions.findIndex(q => q.id_question === questionId);
      if (currentIndex < this.currentCas.questions.length - 1) {
        this.tab = currentIndex + 1;
      } else {
        this.closeExam();
      }
    },
    async fetchData() {
      await fetch('https://newapi.seo-elp.fr/api/caspratique/')
          .then(response => response.json())
          .then(data => {
            this.casPratiques = this.normalizeData(data);
          })
          .catch(error => console.error('Error loading the data: ', error));
    },
    normalizeData(data) {
      const casMap = new Map();
      data.forEach(item => {
        if (!casMap.has(item.id_cas_pratique)) {
          casMap.set(item.id_cas_pratique, {
            id_cas_pratique: item.id_cas_pratique,
            libelle_cas_pratique: item.libelle_cas_pratique,
            actif : item.actif,
            temps : item.temps,
            argent : item.argent,
            questions: []
          });
        }
        const cas = casMap.get(item.id_cas_pratique);
        let question = cas.questions.find(q => q.id_question === item.id_question);
        if (!question) {
          question = {
            id_question: item.id_question,
            libelle_question: item.libelle_question,
            contenu_question: item.contenu_question,
            cout: item.cout,
            type_question: item.libelle_type_question,
            reponses: [],
            cours: {
              id_cours: 1,
              libelle_cours: "Contenu texte",
              competence: {
                id_competence: 1,
                libelle_competence: "competence1"
              }
            }
          };
          cas.questions.push(question);
        }
        question.reponses.push({
          id_reponse: item.id_reponse,
          valeur: item.valeur,
          points_attribution: item.points_attribution
        });
      });
      return Array.from(casMap.values());
    },
    createExam() {
      this.$router.push({ name: 'ExamCreator' });
    },
    async openExam(cas) {
      this.currentCas = { ...cas };
      this.remainingTime = parseInt(this.currentCas.temps) * 60;
      await myaxios.post('/api/caspratique/start', { id_cas_pratique: cas.id_cas_pratique, idUser: this.$store.state.user.idUser })
          .then(response => {
            this.idTentative = response.data.idTentative;
          })
          .catch(error => console.error('Failed to start the exam:', error));
      this.timer = setInterval(() => {
        if (this.remainingTime > 0) {
          this.remainingTime -= 1;
        } else {
          this.stopTimer();
          alert('Le temps est écoulé !');
          this.closeExam();
        }
      }, 1000);
    },
    stopTimer() {
      clearInterval(this.timer);
      this.timer = null;
    },


    async closeExam() {
      await myaxios({
        method: 'post',
        url: '/api/caspratique/end',
        data: { id_tentative: this.idTentative }
      })
          .then(response => {
            this.examResult = response.data; // Store the result
            this.showResultDialog = true; // Show the dialog
          })
          .catch(error => console.error('Failed to end the exam:', error));
      this.stopTimer();
      this.currentCas = null;
      this.tab = 0;
    },
  },
  async mounted() {
    await this.fetchData();
  }
}
</script>

<style scoped>
.v-subheader {
  font-weight: bold;
}
</style>
