<template>
	<div style="height: 100%; background: url('img/fondAvecForme.svg') repeat">
<!--		<img src="img/fondAvecForme.svg" alt="fond" style="height: 100%; width: 100%">-->
<!--		<h3 class="w-100 text-center p-4" style="background-color: #3d54d9; color:white" v-if="$store.state.user.role">-->
<!--			Tutoriel et guide utilisateur du site SEO-ELP<br>-->
<!--			<a href="/tuto.pdf"><v-btn>Cliquer-ici</v-btn></a>-->
<!--		</h3>-->
		<div style="position: absolute; top: 50vh; transform: translate(-50%,-50%); left: 50%">
			<h1>Bienvenue {{user.username}}</h1>
			<div class="sous-titre">
				<p>Role : {{["ADMINISTRATEUR","FORMATEUR","ETUDIANT"][user.idRole-2]}}</p>
				<p v-if="user.classe">Classe : {{user.classe.libelle}}</p>
			</div>
			<div style="display: flex; justify-content: center; flex-wrap: wrap; margin: 0 10%">
				<v-btn class="bouton" @click="$router.push('/cours')">Liste des cours et des cas pratiques</v-btn>
				<v-btn class="bouton" @click="$router.push('/notes')">Mes Notes</v-btn>
				<v-btn class="bouton" v-if="user.role!=='ETUDIANT'" @click="$router.push('/classes')">Liste des classes</v-btn>
			</div>
			<p style="text-align: center"><v-btn @click="showDialog=true">Changer son mot de passe</v-btn></p>
		</div>
		<v-dialog v-model="showDialog">
			<v-card>
				<v-card-title>Changer mot de passe</v-card-title>
				<v-card-text>
					<v-text-field label="Ancien mot de passe" type="password" v-model="ancienPWD"></v-text-field>
					<v-text-field label="Nouveau mot de passe" type="password" v-model="nouveauPWD"></v-text-field>
					<v-text-field label="Confirmer mot de passe" type="password" v-model="confirmPWD"></v-text-field>
				</v-card-text>
				<v-card-actions>
					<v-btn>Annuler</v-btn>
					<v-btn @click="modifPWD">Valider</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import myaxios from "@/service/myaxios";
// import background from "@/components/AccueilBack";

export default {
	name: "Home_Etudiant",
	// components: {
	// 	background
	// },
	computed: {
		user() { return this.$store.state.user },
	},
	data:()=>({
		showDialog:false,
		ancienPWD:"",
		nouveauPWD:"",
		confirmPWD:""
	}),
	methods:{
		modifPWD(){
			if (this.nouveauPWD!==this.confirmPWD) return alert("Les nouveaux mots de passe ne correspondent pas");
			if (this.nouveauPWD.length<6) return alert("Le nouveau mot de passe doit contenir au moins 6 caractères");
			myaxios.put("/api/auth/changePassword", {idUser:this.user.idUser, ancien:this.ancienPWD, nouveau:this.nouveauPWD})
				.then(res=>{
					if (res.data.success===1) {
						alert("Mot de passe modifié");
						this.showDialog=false;
					}
					else alert(res.data.message);
				})
				.catch(err=>alert(err));
		}
	}
}
</script>

<style scoped lang="scss">
@import "../assets/scss/_variables.scss";

.bouton{
	margin: 10px;
	cursor: pointer;
	text-transform: uppercase;
	word-wrap: break-word;
}
h1{
	font-size: 3em;
	color: $primary-color;
	text-align: center;
	font-family: $big-font;
	font-weight: 700;
}
.sous-titre{
		font-size: 3vh;
		color: #222222;
		text-align: center;
		font-family: $big-font;
}
</style>