<template>
  <div>
    <v-text-field label="Label Question" v-model="details.libelle_question" />
    <v-text-field label="Content Question" v-model="details.contenu_question" />
    <v-list>
      <v-list-item v-for="(option, index) in details.options" :key="index">
        <v-text-field v-model="option.valeur" label="Option Text" />
        <v-text-field v-model="option.points_attribution" label="Value" type="number" min="0" />
        <div
            class="drop-area"
            @click="triggerFileInput(index)"
            @dragover.prevent
            @drop.prevent="handleDrop($event, index)"
            @dragenter.prevent="dragEnter($event, index)"
            @dragleave.prevent="dragLeave($event, index)"
        >
          Drop image here or click to select
          <input
              type="file"
              @change="handleFileChange($event, index)"
              style="display: none;"
              ref="fileInput"
          />
        </div>
        <img :src="option.image" alt="Uploaded Image" v-if="option.image">
        <v-btn icon color="red" @click="removeOption(index)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-list-item>
    </v-list>
    <v-btn color="primary" @click="addOption">Add Option</v-btn>
  </div>
</template>


<script>
export default {
  data() {
    return {
      details: {
        id_type_question: 3,
        libelle_type_question: "QCM avec image",
        libelle_question: '',
        contenu_question: '',
        options: []
      }
    };
  },
  methods: {
    addOption() {
      this.details.options.push({ valeur: '', points_attribution: 0, image: '' });
    },
    removeOption(index) {
      this.details.options.splice(index, 1);
    },
    handleFileChange(event, index) {
      const file = event.target.files[0];
      if (file) {
        this.readFile(file, index);
      }
    },
    triggerFileInput(index) {
      this.$refs.fileInput[index].click();
    },
    handleDrop(event, index) {
      const file = event.dataTransfer.files[0];
      if (file) {
        this.readFile(file, index);
      }
    },
    readFile(file, index) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.$set(this.details.options[index], 'image', e.target.result);
      };
      reader.readAsDataURL(file);
    },
    dragEnter(event) {
      // Optional: Add styling or effects when dragging
      event.currentTarget.style.borderColor = 'blue';
    },
    dragLeave(event) {
      // Optional: Remove styling or effects when not dragging
      event.currentTarget.style.borderColor = '#ccc';
    }
  },
  watch: {
    details: {
      handler() {
        this.$emit('input', this.details);
      },
      deep: true
    }
  }
};
</script>

<style>
.drop-area {
  border: 2px dashed #ccc;
  padding: 10px;
  text-align: center;
  cursor: pointer;
}
.drop-area:hover, .drop-area.dragover {
  background-color: #f8f8f8;
  border-color: blue;
}
</style>