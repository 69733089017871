import myaxios from "./myaxios";

async function uploadImageCoursFromAPI(image){
    return myaxios.post('/api/image/',image, 'UPLOAD')
}

async function uploadImageCoursHomePage(image) {
    let answer = await uploadImageCoursFromAPI(image);
    return answer;
}

export {
    uploadImageCoursHomePage
}