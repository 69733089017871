import axios from 'axios'
import store from "@/store";
import router from "@/router";

const myaxios = axios.create({
	baseURL: process.env.NODE_ENV==="development"?'http://localhost:3000/':'https://newapi.seo-elp.fr',
	// withCredentials: true,
});

var reconnexionNecessaire=false
function reconnexionOK() {
	reconnexionNecessaire=false
}

myaxios.interceptors.request.use(
	config => {
		const token = store.state.user ? store.state.user.token : null;
		return {...config, headers: {authorization: token}}
	},
	error => {
		return Promise.reject(error)
	}
)

myaxios.interceptors.response.use(
	res => {
		return res
	},
	error => {
		if (error.response.status === 403 && error.response.data.err === 1) {
			console.error("ERROR => ", error.response.status, error.response.statusText, error.response.data)
			if (!reconnexionNecessaire) {
				reconnexionNecessaire = true
				store.commit("setUser", {});
				alert("Votre session a expiré. Veuillez vous reconnecter.")
				router.push("/connexion")
				// return Promise.reject(error.response.data)
			}
		} else {
			console.error("ERROR => ", error.response.status, error.response.statusText, error.response.data)
			return Promise.reject(error)
		}
	}
)



export default myaxios
export {reconnexionOK}
