import myaxios from "./myaxios";

async function getEtablissements() {
    let answer = await getEtablissementFromAPI();
    return answer;
}

async function getEtablissementFromAPI(){
    return myaxios.get('/api/etablissement/', 'GETALLEtablissement')
}

async function getEtablissementById(id) {
    let answer = await await getEtablissementByIdFromAPI(id);
    return answer;
}

async function getEtablissementByIdFromAPI(id){
    return myaxios.get('/api/etablissement/'+id, 'GETEtablissement')
}

async function createEtablissementFromAPI(data){
    return myaxios.post('/api/etablissement/ajout',data, 'CREATEEtablissement')
}

async function createEtablissement(data) {
    let answer = await createEtablissementFromAPI(data);
    return answer;
}

async function deleteEtablissementFromAPI(id){
    return myaxios.delete('/api/etablissement/suppression/'+id, 'DELETEEtablissement')
}

async function deleteEtablissement(id) {
    let answer = await deleteEtablissementFromAPI(id);
    return answer;
}

export {
    createEtablissement, deleteEtablissement, getEtablissementById, getEtablissements
}