<template>
	<div>
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons/font/bootstrap-icons.css">
		<div class="coursWrapper test" v-if="user.username !== '0'">

      <v-switch v-if="user.role === 'FORMATEUR'"
          v-model="afficherMesCours"
          label="Afficher uniquement mes cours"
      ></v-switch>


      <h1 style="text-align: center">Mes cours</h1>

			<div style="display: flex" v-if="user.role !== 'ETUDIANT'">
        <div style="border: black 1px solid; margin: 5px; width: 100%; padding: 10px" >
          Ajouter une compétence :
          <v-text-field v-model="libelleCompetence" label="Nom de la compétence" />
          <v-btn @click="creerCompetence">Ajouter</v-btn>
        </div>
				<div style="border: black 1px solid; margin: 5px; width: 100%; padding: 10px">
					Ajouter un cours :
					<v-select label="Selectionner la compétence" :items="competences"
							  item-text="libelle_competence" return-object v-model="selectedCompetence"  />
          <span>{{ descriptionCreation() }} </span>
          <br>
          <v-btn @click="creerCours">Ajouter</v-btn><br>
				</div>
<!--				<div style="border: black 1px solid; margin: 5px; width: 100%; padding: 10px" >-->
<!--					Dupliquer cours ou cas pratique :<br>-->
<!--					<v-select label="Cas pratique ou cours source" :items="listeCoursEtCP"-->
<!--							  item-text="libelle" return-object v-model="niveauActu" dense/>-->
<!--					<v-select label="Module destination" :items="listeModuleCours"-->
<!--							  item-text="libelle" return-object v-model="sousModuleActu" dense/>-->
<!--          <v-btn @click="dupliquerNiveau">Ajouter</v-btn>-->
<!--				</div>-->
			</div>
			<div v-if="user.role !== 'ETUDIANT'">
				<span style="margin: auto 0">Vous avez la possibilité de rendre vos cours visibles/invisbles à l'aide des boutons :
          <button class="btn btn-sm btn-success">
									<i class="fas fa-eye"></i>
          </button>
          <button class="btn btn-sm btn-danger">
									<i class="fas fa-eye-slash"></i>
          </button>
      </span>
			</div>


<!--				<div v-if="module.sousModules.length===0 && module.cours[0] " class="module" style="padding-bottom: 10px">-->
<!--					<span @click="changeRoute(0,module.cours[0])" class="lien" style="margin-left: 20px" v-if="user.role === 'ADMIN' || module.cours[0].actif || user.idUser===module.cours[0].idFormateur">-->
<!--						{{ module.cours[0].libelle }}-->
<!--					</span>-->
<!--				</div>-->

      <v-expansion-panels popout>
        <v-expansion-panel v-for="(competence, index) in filteredCompetences" :key="index">
          <v-expansion-panel-header >
            <template v-if="editingCompetence === competence">
              <v-text-field
                  v-model="tempCompetenceName"
                  label="Edit Name"
                  dense
                  solo
                  flat
                  class="edit-transition highlight-input"
                  :class="{'input-visible': editingCompetence === competence, 'input-hidden': editingCompetence !== competence}"
              ></v-text-field>
              <v-btn icon class="edit-button" @click="confirmEdit">
                <v-icon color="green" size="x-large">mdi-check</v-icon>
              </v-btn>
              <v-btn icon class="edit-button" @click="cancelEdit">
                <v-icon color="red" size="x-large">mdi-close</v-icon>
              </v-btn>
            </template>
            <template v-else>
              {{ competence.libelle_competence }}
              <v-tooltip bottom v-if="user.role !== 'ETUDIANT'">
                <template v-slot:activator="{ on, attrs }">
                  <button class="btn btn-icon-only" v-bind="attrs" v-on="on" @click.stop="startEdit(competence)">
                    <i class="bi bi-pencil"></i>
                  </button>
                </template>
                <span>Éditer la compétence</span>
              </v-tooltip>
              <v-tooltip bottom v-if="user.role !== 'ETUDIANT'">
                <template v-slot:activator="{ on, attrs }">
                  <button class="btn btn-icon-only" v-bind="attrs" v-on="on" @click.stop="confirmFirstStep(competence)">
                    <i class="bi bi-trash"></i>
                  </button>
                </template>
                <span>Supprimer la compétence</span>
              </v-tooltip>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list>
              <v-list-item v-for="(course, courseIndex) in competence.courses" :key="courseIndex" class="course-listing">
                <v-list-item-content>
                  <v-list-item-title @click="changeRoute(course)">
                    {{ course.libelle_cours }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-if="((user.role === 'ADMIN') || (user.role === 'FORMATEUR' && user.idUser === course.idUser))" icon v-bind="attrs" v-on="on" @click.stop="editCourse(course)">
                        <v-icon color="orange">mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Éditer le cours</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-if="((user.role === 'ADMIN') || (user.role === 'FORMATEUR' && user.idUser === course.idUser))" icon v-bind="attrs" v-on="on" @click.stop="modif(course, 'course')">
                        <v-icon :color="course.actif ? 'green' : 'red'">
                          {{ course.actif ? 'mdi-eye' : 'mdi-eye-off' }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ course.actif ? 'Cours visible' : 'Cours caché' }}</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-if="((user.role === 'ADMIN') || (user.role === 'FORMATEUR' && user.idUser === course.idUser))" icon v-bind="attrs" v-on="on" @click.stop="confirmDeleteCourse(course)">
                        <v-icon color="brown">mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Supprimer le cours</span>
                  </v-tooltip>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

    <div>
      <v-dialog v-model="showDialog" persistent max-width="500px">
        <v-card>
          <v-card-title class="headline">
            {{ confirmationStep === 1 ? 'Confirmez la suppression' : 'Confirmer à nouveau' }}
          </v-card-title>
          <v-card-text>
            {{ confirmationStep === 1
              ? 'Êtes-vous sûr de vouloir supprimer cette compétence ? Ceci supprimera toutes les questions et les cours liés à cette compétence ! Cette action est irréversible.'
              : 'Cette action supprimera définitivement la compétence et tous les cours associés. Veuillez confirmer à nouveau.' }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="cancelConfirmation">Annuler</v-btn>
            <v-btn color="red darken-1" text @click="handleConfirmation">Confirmer</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-dialog v-model="showCourseDialog" persistent max-width="500px">
      <v-card>
        <v-card-title class="headline">Confirmer la suppression</v-card-title>
        <v-card-text>Cette action supprimera définitivement le cours et toutes les données associées. Êtes-vous sûr de vouloir continuer ?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="showCourseDialog = false">Annuler</v-btn>
          <v-btn color="red darken-1" text @click="deleteCourse(currentCourse)">Supprimer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import myaxios from "@/service/myaxios";
import {mapActions} from "vuex";
// import '../../node_modules/animate.css/animate.css';

export default {
	name: 'App',
  data: () => ({
    competences: [],
    selectedCompetence: null,
    libelleCompetence: '',
    afficherMesCours: false,
    editingCompetence: null,
    tempCompetenceName: '',
    showDialog: false,
    currentCompetence: null,
    confirmationStep: 1,
    showCourseDialog: false,
    currentCourse: null,
  }),
  methods: {
    ...mapActions(['deleteCoursStore', 'deleteCompetenceStore']),
    modif(course, type) {
      const action = !course.actif;
      console.log(`Updating ${type} status to ${action ? 'active' : 'inactive'}`);
      myaxios.patch(`/api/cours/${action ? 'activation' : 'desactivation'}/${course.id_cours}`)
          .then((response) => {
            console.log(response.data);
            course.actif = action;
          })
          .catch(error => {
            console.error("Error updating course status:", error);
          });
    },
    startEdit(competence) {
      this.editingCompetence = competence;
      this.tempCompetenceName = competence.libelle_competence;
    },
    cancelEdit() {
      this.editingCompetence = null;
      this.tempCompetenceName = '';
    },
    async confirmEdit() {
      let res = await myaxios.patch(`/api/competences/${this.editingCompetence.id_competence}`, {name: this.tempCompetenceName});
      location.reload();
      return res;
    },
    confirmDeleteCourse(course) {
      this.currentCourse = course;
      this.showCourseDialog = true;
    },

    deleteCourse(course) {
      if (!course) return;
      this.deleteCoursStore(course.id_cours)
          .then(() => {
            this.competences.forEach(comp => {
              comp.courses = comp.courses.filter(c => c.id_cours !== course.id_cours);
            });
            this.showCourseDialog = false;
          })
          .catch(error => {
            console.error('Failed to delete course:', error);
          });
    },
    editCourse(course) {
      this.$router.push({ name: 'Edit_cours', params: { id_cours: course.id_cours } });
    },
    descriptionCreation() {
      if (!this.sousModuleActu) return "";
      return this.sousModuleActu.ordreN1 === 2 ? "vous vous apprêtez à créer un cours" : "vous vous apprêtez à créer un cas pratique";
    },
    changeRoute(course) {
      this.$router.push({ name: 'Cours_show', params: { id: course.id_cours } });

    },
    async creerCours(){
      this.$router.push({name: 'Createur_cours', params: {id_competence: this.selectedCompetence.id_competence}});
    },

    async creerCompetence(){
      let res = await myaxios.post("/api/competences", {name: this.libelleCompetence});
      console.log(res);
      location.reload();
    },


    handleConfirmation() {
      if (this.confirmationStep === 1) {
        this.confirmationStep = 2; // Passe à la deuxième confirmation
      } else {
        this.deleteCompetence(this.currentCompetence); // Effectue la suppression
        this.resetDialog();
      }
    },
    cancelConfirmation() {
      this.resetDialog();
    },
    resetDialog() {
      this.showDialog = false;
      this.confirmationStep = 1; // Réinitialise pour la prochaine fois
      this.currentCompetence = null;
    },

    deleteCompetence(competence) {
      this.deleteCompetenceStore(competence.id_competence);
      this.competences = this.competences.filter(c => c.id_competence !== competence.id_competence);
    },


    confirmFirstStep(competence) {
      this.currentCompetence = competence;
      this.showDialog = true;
      this.confirmationStep = 1;
    }
	},

	computed: {
		user() {
			return this.$store.state.user
		},

    filteredCompetences() {
      return this.competences.map(competence => ({
        ...competence,
        courses: competence.courses.filter(course => !this.afficherMesCours || (course.actif && course.idFormateur === this.user.idUser))
      }));
    },
  },
  mounted() {
    if ((this.user.role === 'ADMIN') || (this.user.role === 'FORMATEUR')) {
      myaxios.get("/api/cours")
          .then(response => {
            this.competences = response.data.data;
          })
          .catch(error => {
            console.error("Failed to fetch competences:", error);
          });
    } else { // for students
      myaxios.get('api/cours/actifs')
          .then(response => {
            this.competences = response.data.data;
          })
          .catch(error => {
            console.error("Failed to fetch competences:", error);
          });
    }
  }
}

</script>

<style lang="scss" scoped>

@import "../assets/scss/variables";

.nomcourstransition-move {
	transition: transform 1s;
}

.coursWrapper {
	/* Y X */
	margin: 3% auto;
	max-width: 1000px;
}

h1 {
	font-family: $big-font;
	text-align: left;
	margin-top: 7%;
}

.module {
	border-style: solid;
	border-width: 0 0 3px 5px;
	border-color: #0d47a1;
}

.lien {
	color: #0d47a1;
	font-size: 1.2em;
	cursor: pointer;
}

.btn-icon-only {
  background: transparent;
  border: none;
  padding: 1px;
  margin-left: 10px;

  .bi {
    font-size: 1.2rem;
    line-height: 1;
    color: #343a40;
  }

  &:hover, &:focus {
    background: transparent;
    .bi {
      color: #0056b3;
    }
  }
}

.highlight-input {
  border: 2px solid #42b983;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.nomModule {
	font-size: 2.5em
}

.edit-inputs {
  display: flex;
  align-items: center;
  gap: 10px;
}

.highlight-input {
  border: 2px solid #42b983;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

@media screen and (max-width: 500px) {
	.nomModule {
		font-size: 2em
	}
}

</style>
