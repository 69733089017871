<template>
  <div class="course-creator">
    <h1 class="title">Modifier un Cours</h1>
    <div class="form-group">
      <input type="text" v-model="courseTitle" placeholder="Titre du cours" class="form-control"/>
    </div>
    <div class="editor-wrapper">
      <Editor
          ref="myEditor"
          @onInit="editorInitialized"
          api-key="w4f04qw3jsqxnt47qqcxi3xs18xi36lh0yvynogxzm8c2d8j"
          :init="editorOptions"
      />
    </div>
    <button type="button" @click="saveContent" :disabled="!editorReady" class="btn btn-success">Ajouter</button>
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue';
import {uploadImageCoursHomePage} from "@/service/imageCours.service";
import {mapActions} from "vuex";

export default {
  name: "Editeur_cours",
  components: {
    Editor
  },
  props: {
    id_cours: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      courseTitle: '',
      courseContent: '',
      editorReady: false,
      editorOptions: {
        height: 650,
        menubar: true,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount'
        ],
        toolbar: 'undo redo | formatselect | ' +
            'bold italic backcolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help | image',
        images_upload_handler: this.handleImageUpload
      }
    }
  },
  watch: {
    editorReady(newValue) {
      if (newValue && this.courseContent) {
        this.setEditorContent();
      }
    },
  },
  methods: {
    ...mapActions(['getCoursByIdStore', 'updateCoursStore']),

    setEditorContent() {
      if (this.$refs.myEditor && this.$refs.myEditor.editor) {
        this.$refs.myEditor.editor.setContent(this.courseContent);
      }
    },

    async handleImageUpload(blobInfo, success, failure) {
      const maxFileSize = 2 * 1024 * 1024;
      if (blobInfo.blob().size > maxFileSize) {
        failure('La taille de l\'image dépasse 2 Mo.');
        return;
      }

      const timestamp = Math.floor(Date.now() / 1000);
      const fileName = `image_cours${timestamp}.jpeg`;
      const file = new File([blobInfo.blob()], fileName, {type: 'image/jpeg'});
      const formData = new FormData();
      formData.append('image', file);

      try {
        const response = await uploadImageCoursHomePage(formData);
        if (response.data && response.data.location) {
          success(response.data.location);
        } else {
          failure('Invalid response: no location provided');
        }
      } catch (error) {
        failure('Upload failed: ' + error.message);
      }
    },

    editorInitialized() {
      if (this.$refs.myEditor && this.$refs.myEditor.editor) {
        this.editor = this.$refs.myEditor.editor;
        this.editorReady = true;
      } else {
        console.error("L'éditeur n'est pas initialisé correctement.");
      }
    },

    async saveContent() {
      if (this.editorReady && this.editor) {
        try {
          const content = this.editor.getContent();
          const payload = {
            idUser: this.$store.state.user.idUser,
            id_cours: this.id_cours,
            libelleCours: this.courseTitle,
            contenuCours: content
          };
          await this.updateCoursStore(payload);
          this.$emit('contentSaved');
          this.$router.push({name: 'Cours'});
        } catch (error) {
          console.error('Failed to save course:', error);
        }
      } else {
        console.error('Éditeur non initialisé ou indisponible');
      }
    },
  },
  async mounted() {
    this.getCoursByIdStore(this.id_cours).then(res => {
      this.courseTitle = res.data.data[0].libelle_cours;
      this.courseContent = res.data.data[0].contenu_cours;
      if (this.editorReady) {
        this.setEditorContent();
      }
    });
  }
}
</script>

<style scoped>
.course-creator {
  max-width: 800px;
  margin: auto;
  padding: 20px;
  box-shadow: 0 0 15px rgba(0,0,0,0.1);
}

.title {
  text-align: center;
  color: #333;
}

.form-group {
  margin-bottom: 20px;
}

.form-control {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.editor-wrapper {
  margin: auto;
  padding-bottom: 20px;
  width: 90%;
  max-width: none;
}

.btn-success {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-success:hover {
  background-color: #45a049;
}
</style>
